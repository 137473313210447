import React from 'react'
import { graphql } from 'gatsby'
import ServicesDesignTemplate from '../../components/pages/servicesDesignTemplate'

const OurServicesSk = ({data}) => (
  <ServicesDesignTemplate data={data} lang={'sk'}/>
)
export const query = graphql`{
    design: file(relativePath: {eq: "pages/sk/services/design.md"}) {
        childMarkdownRemark {
            frontmatter {
                language
                title
                subtitle
                description
                services {
                    title
                    subtitle
                    description
                    icon {
                        publicURL
                    }
                    service
                    alt
                    background_color
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid( maxWidth: 700) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export default OurServicesSk

